
import { Prop, Component, Vue } from "vue-property-decorator";

@Component({})
export default class VAccordion extends Vue {
  @Prop({ default: true, required: true }) readonly opened!: boolean;
  @Prop({ default: false }) readonly withSubheader!: boolean;
  closed = !this.opened;

  @Prop({ default: false, required: false }) readonly hideControls!: boolean;

  toggleOpenClose() {
    if (this.hideControls) {
      return;
    }
    this.closed = !this.closed;
  }
}
